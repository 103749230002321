import React from 'react';
import { IntlProvider, FormattedMessage } from "react-intl";
import jwt_decode from "jwt-decode";

import moment from 'moment';
import 'moment/min/locales';

import { EnumServiceType, EnumActionType, EnumASCloudAPIType, EnumDataType, EnumThemeType, EnumAccountType, EnumErrorCode, EnumErrorMessage, EnumWebSocketCmd,
        EnumLogType, EnumLogMessage, EnumGateFormat, IsEventLog, EnumMessageType, EnumDirectionType } from './modules/ASUtils/ASConfig';
import ASUtils, { isWinPlatform, ASConfigComponent, WsPlayerDetector, setLogSnapshotsSatuses } from './modules/ASUtils/ASUtils';
import VMSGraphql from './modules/LogApp/VMSGraphql';

export const Constants = {
    gvCloudUrl: process.env.REACT_APP_URL_BASE,
    gvCloudAPIUrl: process.env.REACT_APP_URL_GVCLOUD_API,
    cloudAccessUrl: process.env.REACT_APP_URL_ACCESS_BASE,
    urls: {
        access: `${process.env.REACT_APP_URL_ASCLOUD_API}ascloud-access`,
        getImage: `${process.env.REACT_APP_URL_ASCLOUD_API}image`,
        querylog: `${process.env.REACT_APP_URL_ASCLOUD_API}log`
    },
    thirdPartyUrls: {
        verifyUser: `${process.env.REACT_APP_URL_GVCLOUD_API}asm/user`,
        refreshGVToken: `${process.env.REACT_APP_URL_GVCLOUD_API}asm/refresh/token`,
        attachmentToken: `${process.env.REACT_APP_URL_GVCLOUD_API}asm/event/attachment/token`,
        webSocket: process.env.REACT_APP_URL_GVCLOUD_WEBSOCKET,
        resouce: `https://cloud-center-data${process.env.REACT_APP_TYPE !== 'release' ? '-stage' : ''}.s3.us-west-1.amazonaws.com/resource/`
    },
    storageNames: {
        theme: 'theme',
        accountInfo: 'accountInfo',
        siderWidth: 'siderWidth',
        collapseSider: 'collapseSider',
        logViewWidth: 'logViewWidth',
        collapseLogview: 'collapseLogview',
        filterRegions: 'filterRegions',
        googleMapZoom: 'googleMapZoom',
        googleMapCenter: 'googleMapCenter',
        googleMapTypeId: 'googleMapTypeId',
        is3dActive: 'is3dActive',
        mapSettings: 'mapSettings',
        mapVideoWidth: 'mapVideoWidth',
        mapVideoHeight: 'mapVideoHeight',
        groupVideoList: 'groupVideoList',
        logviewMode: 'logviewMode',
        logviewSettings: 'logviewSettings',
        logAutoSelect: 'logAutoSelect',
        logFilters: 'logFilters',
        shapePolygonColor: 'shapePolygonColor',
        shapeSectorColor: 'shapeSectorColor',
        recentColor: 'recentColor',
        excludeAnnIds:  'excludeAnnIds',
        disabledWSPlayer: 'disabledWSPlayer'
    }
};

export const EnumServerType = {
    VMS: 0,
    Access: 1
};

export const EnumMapNodeType = {
    ImgMap: 0,
    Door: 1,
    Camera: 2
};

export const EnumShapeType = {
    Polygon: 0,
    Sector: 1
};

export const EnumMapVideoPosType = {
    Pin: 0,
    PinOffset: 1,
    Independent: 2
};

const defaultAccountInfo = {
    // frome GVCloud
    gv_user_token: '',
    gv_exp: 0,
    lang: 'en',
    aid: 0,   // GV-Cloud Account ID
    id: 0,    // AS-Cloud Account ID
    master: 0,
    name: '',
    email: '',
    theme: EnumThemeType.OSDefault,
    profile_picture: '',
    subscribed_service: [],
    time_format: 'MM-DD-YYYY HH:mm:ss',

    // frome ASCloud
    type: EnumAccountType.user,    // 0: general user, 1: admin, 2: Region Admin
    suspend: 0,     // Account suspend status(0: false, 1: true)
    version: '',    // 1.0.0 (ASCloud version, 每碼最多3位)
};

export const defaultMapSettings = {
    icon_size: 48,
    format: EnumGateFormat.Gate,
    font_size: 14,
    font_color: '#ffffff',
    background_color: '#00a2e8cd'
};

// const fakeMapList = [
//     {map_id: 1, pid: 0, map_name: 'Geovision', img_src: mapSample1, pos_x: 121.56614407896996, pos_y: 25.08198053424554},
//     {map_id: 2, pid: 1, map_name: '7F', img_src: mapSample2, pos_x: 392, pos_y: 352, shape: {t: EnumShapeType.Polygon, cr: '#00CD00', pts: [{x:-100,y:-100}, {x:100,y:-100}, {x:100,y:100}, {x: 0, y: 150}, {x:-100,y:100}]}},
//     // {id: 3, pid: 1, map_name: '9F', img_src: mapSample3, pos_x: 189, pos_y: 164, shape: {t: EnumShapeType.Polygon, cr: '#CD0000', pts: [{x:-50,y:-50}, {x:50,y:-50}, {x:50,y:50}, {x:-50,y:50}]}},
//     {map_id: 3, pid: 1, map_name: '9F', img_src: mapSample3, pos_x: 189, pos_y: 164, shape: {t: EnumShapeType.Sector, cr: '#0064c8', r: 100, sa: 108, a:60}},
// ];

export const AppContext = React.createContext({});
export class AppContextProvider extends React.Component {
    _initData = {
        mapList: false,
        vmsRegionList: false,
        regionList: false,
        vmsList: false,
		deviceList: false
	};

    _requestDeviceStatus = 0;   // 0: none, 1: requesting, 2: padding

    // for websocket
    _wsNotifys = [];

    _focusVideoTimer = null;

    _timerUpdateToken = null;
    _timerRefreshVMSList = null;

    constructor(props) {
        super(props);

        var mapSettings = {...defaultMapSettings};
        try {
            var config = JSON.parse(window.localStorage.getItem(Constants.storageNames.mapSettings));
            if (config) {
                mapSettings = {
                    ...mapSettings,
                    ...config
                };
            }
        } catch {}

        var groupVideoList = [];
        try {
            groupVideoList = JSON.parse(window.localStorage.getItem(Constants.storageNames.groupVideoList));
            if (!Array.isArray(groupVideoList)) {
                groupVideoList = [];
            }
        } catch {}

        this.state = {
            serviceType: EnumServiceType.Map,
            supportAccess: false,
            locale: undefined,
            accountInfo: {
                ...defaultAccountInfo,
                theme: parseInt(window.localStorage.getItem(Constants.storageNames.theme)) || EnumThemeType.OSDefault
            },
            wsPlayerConfig: {
                isSupport: false,
                url: 'https://cloud-center-data-stage.s3.us-west-1.amazonaws.com/resource/wsplayer/GeoWebPlayerSetup.exe',
                version: 0,
                version_text: '',
                current_version: 0,
                disabled: window.localStorage.getItem(Constants.storageNames.disabledWSPlayer) ? 1 : 0
            },

            debugMsg: this.debugMsg,

            verifyUser: this.verifyUser,
            ajaxLogin: this.ajaxLogin,

            ajaxASCloud: this.ajaxASCloud,
            ajaxThirdParty: this.ajaxThirdParty,

            sendWS: this.sendWS,
            addWSNotification: this.addWSNotification,
            removeWSNotification: this.removeWSNotification,

            mapSettings: mapSettings,
            setMapSettings: this.setMapSettings,

            isEdit: false,
            setIsEdit: (val) => this.setState({isEdit: !!val}),

            is3D: window.localStorage.getItem(Constants.storageNames.is3dActive) ? true : false,
            setIs3D: (val) => {
                this.setState({is3D: !!val});
                window.localStorage.setItem(Constants.storageNames.is3dActive, val ? '1' : '');
            },

            disabledWSPlayer: this.disabledWSPlayer,

            isVmsRegionAdmin: this.isVmsRegionAdmin,
            isRegionAdmin: this.isRegionAdmin,

            mapList: [],
            getMapList: this.getMapList,
            getMapData: this.getMapData,
            deleteMap: this.deleteMap,
            setMapPin: this.setMapPin,
            setMapShape: this.setMapShape,

            vmsRegionList: [],
            getVmsRegionList: this.getVmsRegionList,

            regionList: [],
            getRegionList: this.getRegionList,

            vmsList: [],
            cameraList: [],
            getVMSList: this.getVMSList,
            setVMSPin: this.setVMSPin,
            
			deviceList: [],
            gateList: [],
			getDeviceList: this.getDeviceList,
            setDevicePin: this.setDevicePin,

            collectPins: this.collectPins,
            getGateName: this.getGateName,
            getLiveStreamInfo: this.getLiveStreamInfo,

            mapVideoList: {},
            setMapVideoList: (val) => { this.setState({mapVideoList: val}) },

            groupVideoList: groupVideoList,
            setGroupVideoList: (val) => { 
                this.setState({groupVideoList: val});
                window.localStorage.setItem(Constants.storageNames.groupVideoList, JSON.stringify(val));
            },

            getAccessLogList: this.getAccessLogList,
            // getLPRLogList: this.getLPRLogList,

            genMapAuditMemo: this.genMapAuditMemo
        };

        this.asUtils = React.createRef();
        this.vmsGraphql = React.createRef();
        this.asConfigRef = React.createRef();
    }

    componentDidMount() {
        this.getLocale();
        window.addEventListener('storage', this.handleStorageChange);

        // check token for every 5 mins
        var _self = this;
        _self._timerUpdateToken = setInterval(() => {
            _self.debugMsg('Utils', 'Check token life');
            _self.updateToken();
        }, 5 * 60 * 1000);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.accountInfo.lang !== prevState.accountInfo.lang) {
            this.getLocale();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.handleStorageChange);

        clearInterval(this._timerUpdateToken);
        clearTimeout(this._timerRefreshVMSList);
    }

    handleStorageChange = (event) => {
        if (event.key === Constants.storageNames.accountInfo) {
            try {
                var temp = JSON.parse(event.newValue);
                if (typeof(temp) === 'object') {
                    this.setState({
                        accountInfo: {
                            ...this.state.accountInfo,
                            ...temp
                        }
                    });
                    this.debugMsg('Storage', 'accountInfo: ' + event.newValue);
                }
            } catch {}
        }
    };

    getLocale = async () => {
        var _self = this;
        try {
            const resp = await fetch(`${window.location.origin}/lang/${_self.state.accountInfo.lang}.json?${new Date().getTime()}`);
            const data = await resp.json();
            const asresp = await fetch(`${window.location.origin}/lang/as-${_self.state.accountInfo.lang}.json?${new Date().getTime()}`);
            const asdata = await asresp.json();
            _self.setState({locale: {
                ...asdata,
                ...data
            }});
            return data;
        } catch (e) {
            console.log(e);
            return null;
        }
    };

    debugMsg = (...args) => {
        this.asUtils.current.debugMsg.apply(null, args);
    };

    /* -------- Account Start -------- */
    _setAccountStorage = () => {
        window.localStorage.setItem(Constants.storageNames.accountInfo, JSON.stringify(this.state.accountInfo));
        window.localStorage.setItem(Constants.storageNames.theme, this.state.accountInfo.theme);
    };

    _mobileLogin = (login_user) => {
        // simulate mobile login
        const urlParams = new URLSearchParams(window.location.search);

        var account_id = '', email = '', password = '';
        if (process.env.REACT_APP_TYPE !== 'release') {
            if (login_user === '2') {
                account_id = 100000;
                email = "odaru@geovision.com.tw";
                password = "password";
            }  else if (login_user === '3') {
                account_id = 100000;
                email = "hanktung@geovision.com.tw";
                password = "[Xcga5AK";
            } else if (login_user === '4') {
                account_id = 372431;
                email = "hanktung@geovision.com.tw";
                password = "Admin123!";
            } else {
                account_id = 372431;
                email = "joinyang@geovision.com.tw";
                password = "!QAZ2wsx";
            }
        }

        if (urlParams.has('account')) {
            account_id = parseInt(urlParams.get('account'));
        }
        if (urlParams.has('email')) {
            email = urlParams.get('email');
        }
        if (urlParams.has('password')) {
            password = urlParams.get('password');
        }
            
        this.vmsGraphql.current.mutateLogin(account_id, email, password, (success, data) => {
            if (success) {
                this._setGvToken(data.token, data, () => {
                    this.vmsGraphql.current.mutationTempToken((success, token) => {
                        if (success) {
                            window.location.search = 'token=' + token;
                        } else {
                            window.location = `${Constants.gvCloudUrl}?redirect=/map&pathname=${window.location.pathname}`;
                        }
                    });
                });
            } else {
                window.location = `${Constants.gvCloudUrl}?redirect=/map&pathname=${window.location.pathname}`;
            }
        });
    };

    _getAccountInfo = () => {
        var _info = { ...this.state.accountInfo };
        if (!this.state.accountInfo.gv_user_token) {
            try {
                var temp = JSON.parse(window.localStorage.getItem(Constants.storageNames.accountInfo));

                if (temp && temp.gv_user_token) {
                    _info = {
                        ..._info,
                        ...temp,
                    };
                    this.setState(_info);
                    this.debugMsg('AccountInfo', 'Get Token From Local Storage');
                }
            } catch {}
        }
        return _info;
    };

    _setAccountInfo = (data, callback) => {
        callback = callback || function() {};

        var _info = {
            ...this.state.accountInfo,
            type: data.a_type,
            suspend: data.suspend,
            version: data.version
        };

        this.setState({accountInfo: _info}, () => {
            this._setAccountStorage();
            callback();
        });
    };

    _setGvToken = (user_token, data, callback) => {
        callback = callback || function() {};

        var _info = {
            ...this.state.accountInfo,
            gv_user_token: user_token,
            gv_exp: new Date(data.exp * 1000).getTime(),
            lang: data.language,
            aid: data.account_id,   // GV-Cloud Account ID
            id: data.user_id,       // AS-Cloud Account ID
            master: data.master,
            email: data.email,
            name: data.name,
            theme: data.theme,
            profile_picture: data.profile_picture,
            subscribed_service: data.subscribed_service,
            time_format: data.time_format
        };

        var supportAccess = Array.isArray(data.subscribed_service) && !!data.subscribed_service.find(item => item.service_id === EnumServiceType.Access);

        this.setState({accountInfo: _info, supportAccess}, () => {
            this.setAccountLang(data.language);
            this._setAccountStorage();
            callback(this.state.accountInfo, supportAccess);
        });
    };

    verifyUser = (callback) => {
        callback = callback || function(){};

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('debug') === '1' || urlParams.get('debug') === '2' || urlParams.get('debug') === '3' || urlParams.get('debug') === '4') {
            this._mobileLogin(urlParams.get('debug'));
            return;
        }

        const redirectUrl = `${Constants.gvCloudUrl}?redirect=/map&pathname=${window.location.pathname}`;

        var headers = { 'Content-Type': 'application/json' };
        if (urlParams.has('token') && urlParams.get('token')) { 
            headers['x-one-time-token'] = urlParams.get('token');

            // clear account info
            window.localStorage.removeItem(Constants.storageNames.accountInfo);
            
            // remove search params
            window.history.pushState({}, document.title, window.location.pathname);
            window.history.replaceState({}, document.title, window.location.pathname);
        } else {
            var info = this._getAccountInfo();
            if (info.gv_user_token && info.gv_exp > new Date().getTime()) {
                headers['x-user-token'] = info.gv_user_token;
            } else {
                // clear account info
                window.localStorage.removeItem(Constants.storageNames.accountInfo);
                window.location = redirectUrl;
                return;
            }
        }

        fetch(Constants.thirdPartyUrls.verifyUser, {
            method: 'GET',
            mode: 'cors',
            headers: new Headers(headers)
        })
        .then(res => res.json())
        .then(data => {
            if (data.success) {
                this._setGvToken(data.user_token, data.data, callback);
                this.setState({
                    wsPlayerConfig: {
                        ...this.state.wsPlayerConfig,
                        ...data.wsplayer
                    }
                });
            } else {
                window.location = redirectUrl;
            }
        })
        .catch(e => {
            window.location = redirectUrl;
            console.log(e);
        });
    };

    ajaxLogin = (callback) => {
        callback = callback || function(){};

        this.asUtils.current.ajaxLogin((success, accountInfo) => {
            if (success) {
                this.setState({supportAccess: true});
                this._setAccountInfo(accountInfo, () => {
                    callback(true, this.state.accountInfo);
                });
                this._receiveAnnouncement(accountInfo.ann_ids);
            } else {
                this.setState({supportAccess: false});
                callback(success, accountInfo);
            }
        });
    };

    setAccountLang = (lang, callback) => {
        callback = callback || function() {};
        this.setState({
            accountInfo: {
                ...this.state.accountInfo,
                lang: lang
            }
        }, callback);
        moment.locale(lang === 'tw' ? 'zh-tw' : lang);
    };

    updateToken = () => {
        var now = new Date().getTime(),
            expTime = this.state.accountInfo.gv_exp;
        if (now < expTime && (expTime - now) < (30 * 60 * 1000)) {
            var oldToken = this.state.accountInfo.gv_user_token;

            this.ajaxThirdParty(Constants.thirdPartyUrls.refreshGVToken, 'GET', {}, data => {
                if (data.success && data.refresh_token !== oldToken) {
                    if (data.refresh_token !== oldToken) {
                        this.setState({
                            accountInfo: {
                                ...this.state.accountInfo,
                                gv_user_token: data.refresh_token,
                                gv_exp: new Date(data.exp * 1000).getTime()
                            }
                        }, () => {
                            this._setAccountStorage();
                            if (this.state.supportAccess) {
                                this.ajaxLogin();
                            }
                        });
                    }
                    this.debugMsg('Utils', 'Update token, ' + (data.refresh_token === oldToken ? 'Same token' : 'Get new one'));
                }
            });
        }
    };
    /* -------- Account End -------- */

    /* -------- WebSocket Start -------- */
    sendWS = (params) => {
        this.asUtils.current.sendWS(params);
    };

    addWSNotification = callback => {
        if (this._wsNotifys.indexOf(callback) === -1) {
            this._wsNotifys.push(callback);
        }
    };

    removeWSNotification = callback => {
        var idx = this._wsNotifys.indexOf(callback);
        if (idx > -1) {
            this._wsNotifys.splice(idx, 1);
        }
    };

    _callWsNotify = (notifyData) => {
        this._wsNotifys.forEach(notify => {
            if (typeof(notify) === 'function') {
                notify(notifyData);
            }
        });
    };
    /* -------- WebSocket End -------- */

    ajaxASCloud = (url, body, callback, notManual) => {
        callback = callback || function(){};
    
        fetch(url, {
            method: "POST",
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json',
                'x-user-token': this.state.accountInfo.gv_user_token
            }),
            body: JSON.stringify(body)
        })
        .then(res => {
            if (res.status === 200) {
                return res.json();
            }
            throw res;
        })
        .then(data => {
            if (data.errcode !== EnumErrorCode.ERR_SUCCESS) {
                console.log(data.errmsg);
                data.errmsg = <FormattedMessage id={EnumErrorMessage[data.errcode] || 'err_msg_internal_error'} />;
            }
            callback(data);
            // if (data.errcode === EnumErrorCode.ERR_TOKEN_INVALID) {
            //     this._wsNotifys.forEach(notify => {
            //         if (typeof(notify) === 'function') {
            //             notify({cmd_id: EnumWebSocketCmd.INVALID_TOKEN});
            //         }
            //     });
            // }
        })
        .catch(e => {
            callback({
                success: false,
                errcode: EnumErrorCode.ERR_INTERNAL_ERROR,
                errmsg: e.toString(),
                status: e.status
            });
            console.log(e);
        });

        if (!notManual) {
            this.updateToken();
        }
    };

    ajaxThirdParty = (url, method, params, callback) => {
        callback = callback || function(){};
        method = method || 'GET';

        var queryUrl = url;
        if (method === 'GET') {
            queryUrl = `${url}?${new URLSearchParams(params).toString()}`;
        }

        var request = {
            method: method,
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json',
                'x-user-token': this.state.accountInfo.gv_user_token
            })
        };

        if (method !== 'GET') {
            request.body = JSON.stringify(params);
        }
        
        fetch(queryUrl, request)
        .then(res => {
            if (res.status === 200) {
                return res.json();
            }
            throw res;
        })
        .then(data => {
            callback({
                errcode: data.success ? 0 : EnumErrorCode.ERR_INTERNAL_ERROR,
                ...data
            });
        })
        .catch(e => {
            var errcode = EnumErrorCode.ERR_INTERNAL_ERROR;
            if (e.status) {
                if (e.status === 401) {
                    errcode = EnumErrorCode.ERR_TOKEN_INVALID;
                } else if (e.status === 400 || e.status === 405) {
                    errcode = EnumErrorCode.ERR_PARAMS_INVALID;
                }
            }
            callback({
                success: false,
                errcode: errcode,
                errmsg: e.toString(),
                status: e.status
            });
            console.log(e);
        })
    };

    _receiveNotification = data => {
		var device_mac, device_id, device_status, gate_id, gate_status, idx, 
            devices = [].concat(this.state.deviceList),
            gateList = [].concat(this.state.gateList);

		const getDevice = (_device_id) => {
            this.ajaxASCloud(Constants.urls.access, {api_cmd: EnumASCloudAPIType.DEVICE, action_type: EnumActionType.Query, device_id: _device_id}, (resp) => {
                if (!resp.errcode && Array.isArray(resp.data) && resp.data.length > 0) {
                    var device = {...resp.data[0]},
                        gates = [];
                    device.gates.forEach((gate, index) => {
                        if (!gate.enable) return;
                        if (index > 0 && gate.gate_id % 2 === 1 && device.gates[index - 1].gate_id === (gate.gate_id - 1) && device.gates[index - 1].enable) {
                            return;   // ignore exit door
                        }

                        // set exit door
                        if (gate.gate_id % 2 === 0 && index < device.gates.length - 1 && device.gates[index + 1].enable && device.gates[index + 1].gate_id === (gate.gate_id + 1)) {
                            gate.exitDoor = device.gates[index + 1];
                        }

                        var gateData = this._genGateFields(device, gate);

                        gates.push(gateData);

                        
                        var pinIndex = gateList.findIndex(item => item.id === gateData.id);
                        if (pinIndex > -1) {
                            gateList[pinIndex] = gateData;
                        } else {
                            gateList.push(gateData);
                        }
                    });
                    device.gates = gates;

                    var _idx = devices.findIndex(item => item.device_id === device.device_id);
                    if (_idx > -1) {
                        devices[_idx] = device;
                    } else {
                        devices.push(device);
                    }

                    this.setState({
                        deviceList: devices,
                        gateList: gateList
                    });
                }
            }, true);
        };

        const updateLockdownStatus = () => {
            this.ajaxASCloud(Constants.urls.access, {api_cmd: EnumASCloudAPIType.DEVICE, action_type: EnumActionType.Query}, (resp) => {
				if (!resp.errcode) {
					resp.data.forEach(device => {
                        var _device = devices.find(item => item.device_id === device.device_id);
                        if (_device) {
                            device.gates.forEach(gate => {
                                var _gate = _device.gates.find(item => item.gate_id === gate.gate_id);
                                if (_gate) {
                                    _gate.gate_ldn = gate.gate_ldn;
                                }

                                var gatePin = gateList.find(item => item.id === `door-${device.device_id}-${gate.gate_id}`);
                                if (gatePin) {
                                    gatePin.gate_ldn = gate.gate_ldn;
                                }
                            });
                        }
					});

                    this.setState({
                        deviceList: devices,
                        gateList: gateList
                    });
				}
			}, true);
        };

        const getGlobalData = (excludes) => {
            if (this._initData.regionList && !excludes?.regionList) this.getRegionList(true, null, true);
            if (this._initData.deviceList && !excludes?.deviceList) this.getDeviceList(true, null, true);
        };

        const handleRegionChange = () => {
            var account_id = this.state.accountInfo.id,
                account_type = this.state.accountInfo.type,
                adminRegions = [];

            const notifyAccountChanged = () => {
                var notifyData = {cmd_id: EnumWebSocketCmd.ACCOUNT_DATA_CHANGED, data1: EnumActionType.Edit};
                this._receiveNotification(notifyData);
                this._callWsNotify(notifyData);
            };
            
            this.state.regionList.forEach(region => {
                if (region.rg_admins.includes(account_id)) {
                    adminRegions.push(region.rg_id);
                }
            });
            this.ajaxLogin((success, accountInfo) => {
                if (success) {
                    if (account_type !== accountInfo.type) {
                        notifyAccountChanged();
                    } else {
                        this.getRegionList(true, resp => {
                            if (!resp.errcode) {
                                var newAdminRegions = [];
                                resp.data.forEach(region => {
                                    if (region.rg_admins.includes(account_id)) {
                                        newAdminRegions.push(region.rg_id);
                                    }
                                });

                                if (adminRegions.length !== newAdminRegions.length || adminRegions.some((val, index) => val !== newAdminRegions[index])) {
                                    notifyAccountChanged();
                                } else {
                                    getGlobalData({regionList: true});
                                }
                            }
                        }, true);
                    }
                }
            });
        };

		switch (data.cmd_id) {
			case EnumWebSocketCmd.DATA_CHANGED:
                var dataTypes = parseInt(data.data1);

                if (document.cookie.includes('debug') || window.localStorage.getItem('debug')) {
                    var changedTypes = [];
                    Object.keys(EnumDataType).forEach(key => {
                        if ((dataTypes & EnumDataType[key]) === EnumDataType[key]) {
                            changedTypes.push(key);
                        }
                    });
                    this.debugMsg('DATA_CHANGED', changedTypes.join(' / '));
                }

                if ((dataTypes & EnumDataType.Region) === EnumDataType.Region) {
                    handleRegionChange();
                }

                if (this._initData.deviceList && 
                    ((dataTypes & EnumDataType.Device) === EnumDataType.Device || (dataTypes & EnumDataType.Region) === EnumDataType.Region || (dataTypes & EnumDataType.GatePin) === EnumDataType.GatePin)) {
                    this.getDeviceList(true, null, true);
                }

                if (this._initData.deviceList && (dataTypes & EnumDataType.GateLockdown) === EnumDataType.GateLockdown) {
                    updateLockdownStatus();
                }

				break;
			case EnumWebSocketCmd.GATE_CAM_CHANGED:
				if (!this._initData.deviceList) return;
				device_mac = data.data1;
				idx = devices.findIndex(item => item.device_mac === device_mac);
				if (idx > -1) {
					device_id = devices[idx].device_id;
					getDevice(device_id);
				}
				break;
			case EnumWebSocketCmd.DEVICE_STATUS_CHANGED:
				if (!this._initData.deviceList) return;
				device_mac = data.data1;
                device_status = parseInt(data.data2);

                idx = devices.findIndex(item => item.device_mac === device_mac);
                if (idx > -1 && Number.isInteger(device_status)) {
                    devices[idx].device_status = device_status;
                    this.setState({
                        deviceList: devices
                    });
                }
				
                if (this._requestDeviceStatus === 1) {
                    this._requestDeviceStatus = 2;
                }
				break;
			case EnumWebSocketCmd.GATE_STATUS_CHANGED:
				if (!this._initData.deviceList) return;
				if (!data.data1.toString().includes('_')) break;
				device_mac = data.data1.split('_')[0];
				gate_id = parseInt(data.data1.split('_')[1]);
				gate_status = parseInt(data.data2);
								
				var device = devices.find(item => item.device_mac === device_mac);
				if (device) {
					var gate = device.gates.find(item => item.gate_id === gate_id);
					if (gate) {
						gate.gate_status = gate_status;
					} else if (gate_id % 2 === EnumDirectionType.Exit) {
                        gate = device.gates.find(item => item.gate_id === (gate_id - 1));
                        if (gate && gate.exitDoor) {
                            gate.exitDoor.gate_status = gate_status;
                        }
                    }
                    if (gate) this.setState({ deviceList: devices });

                    var gatePin = gateList.find(item => item.id === `door-${device.device_id}-${gate_id}`);
                    if (gatePin) {
                        gatePin.gate_status = gate_status;
                    } else if (gate_id % 2 === EnumDirectionType.Exit) {
                        gatePin = gateList.find(item => item.id === `door-${device.device_id}-${gate_id-1}`);
                        if (gatePin && gatePin.exitDoor) {
                            gatePin.exitDoor.gate_status = gate_status;
                        }
                    }
                    if (gatePin) this.setState({ gateList: gateList });
				}
                if (this._requestDeviceStatus === 1) {
                    this._requestDeviceStatus = 2;
                }
				break;

            case EnumWebSocketCmd.ACCOUNT_DATA_CHANGED:
                var action_type = parseInt(data.data1);
                if (action_type === EnumActionType.Edit) {
                    this.ajaxLogin((success) => {
                        if (success) {
                            getGlobalData();
                        }
                    });
				}
                break;

            case EnumWebSocketCmd.SERVER_MAINTAIN:
            case EnumWebSocketCmd.CLIENT_RELOAD:
                window.location.reload();
                break;

            case EnumWebSocketCmd.RECONNECT_WEBSOCKET:
                getGlobalData();
				break;

            case EnumWebSocketCmd.VMS_WEBSOCKET:
                var vmsRegionList, index;
                switch(data.type) {
                    case 'WSPLAYER_UPDATE':
                        if (data.action === 'NEW_VERSION_AVAILABLE') {
                            this.setState({
                                wsPlayerConfig: {
                                    ...this.state.wsPlayerConfig,
                                    ...data.payload
                                }
                            });
                        }
                        break;
                    case 'REGION_CHANGE':
                        if (!this._initData.vmsRegionList) break;
                        vmsRegionList = [...this.state.vmsRegionList];
                        index = vmsRegionList.findIndex(region => region.rg_id === data.id);
                        if (data.action === 'CREATE') {
                            if (index > -1) break;
                            vmsRegionList.push({
                                rg_id: data.id,
                                rg_name: data.name,
                                rg_admins: []
                            });
                        } else if (data.action === 'UPDATE') {
                            if (index === -1) break;
                            vmsRegionList[index].rg_name = data.name;
                        } else if (data.action === 'DELETE') {
                            if (index === -1) break;
                            vmsRegionList.splice(index, 1);
                        } else break;
                        this.setState({vmsRegionList});
                        break;
                    case 'REGION_MANAGER_CHANGE':
                        if (!this._initData.vmsRegionList) break;
                        vmsRegionList = [...this.state.vmsRegionList];
                        index = vmsRegionList.findIndex(region => region.rg_id === data.region_id);
                        if (index > -1) {
                            vmsRegionList[index].rg_admins = data.user_id;
                            this.setState({vmsRegionList});
                        }
                        break;
                    case 'ACCOUNT_USER_CHANGE':
                        if (data.id !== this.state.accountInfo.id) return;
                        if (data.action === 'UPDATE' && data.name) {
                            this.setState({
                                accountInfo: {
                                    ...this.state.accountInfo,
                                    name: data.name
                                }
                            }, this._setAccountStorage);
                        }
                        break;
                    case 'USER_LANGUAGE_CHANGE':
                        this.setAccountLang(data.value, this._setAccountStorage);
                        break;
                    case 'USER_DATA_CHANGE':
                        var params = {};
                        if (data.action === 'avatar') {
                            params['profile_picture'] = data.value;
                        } else if (data.action === 'theme') {
                            params['theme'] = data.value;
                        } else if (data.action === 'time_format') {
                            params['time_format'] = data.value;
                        }
                        this.setState({
                            accountInfo: {
                                ...this.state.accountInfo,
                                ...params
                            }
                        }, this._setAccountStorage);
                        break;
                    default:
                        break;
                }
                break;
			default:
				break;
		}
	};

    _receiveAnnouncement = async (strAnnIds) => {
        if (!strAnnIds) return;
        var annIds = [];
        try {
            annIds = JSON.parse(strAnnIds);
        } catch(e) { this.debugMsg('receiveAnnouncement', e.toString()); }

        if (!Array.isArray(annIds) || annIds.length === 0) return;

        var _self = this, i;
        for (i = 0; i < annIds.length; i++) {
            if (!_self.state.locale[`ann.${annIds[i]}`]) {
                var _locale = await _self.getLocale();
                if (!_locale || !_locale[`ann.${annIds[i]}`]) continue;
            }

            _self.asConfigRef.current.showAnnouncement(annIds[i]);
        }
    };

    setMapSettings = (settings) => {
        this.setState({
            mapSettings: {
                ...defaultMapSettings,
                ...settings
            }
        }, () => {
            window.localStorage.setItem(Constants.storageNames.mapSettings, JSON.stringify(this.state.mapSettings));
        });
    };

    disabledWSPlayer = (disabled) => {
        disabled = disabled ? 1 : 0;
        this.setState({
            wsPlayerConfig: {
                ...this.state.wsPlayerConfig,
                disabled
            }
        });
        window.localStorage.setItem(Constants.storageNames.disabledWSPlayer, disabled ? '1': '');
    };

    isVmsRegionAdmin = rg_id => {
        if (this.state.accountInfo.type === EnumAccountType.admin) {
            return true;
        } else {
            if (typeof(rg_id) === 'undefined') {
                return this.state.accountInfo.type === EnumAccountType.regionAdmin
            } else {
                return this.state.vmsRegionList.some(item => item.rg_id === rg_id && item.rg_admins.includes(this.state.accountInfo.id));
            }
        }
    };

    isRegionAdmin = rg_id => {
        if (this.state.accountInfo.type === EnumAccountType.admin) {
            return true;
        } else {
            if (typeof(rg_id) === 'undefined') {
                return this.state.accountInfo.type === EnumAccountType.regionAdmin
            } else {
                return this.state.regionList.some(item => item.rg_id === rg_id && item.rg_admins.includes(this.state.accountInfo.id));
            }
        }
    };

    _genMapFields = (mapData) => {
        var shape;
        try {
            shape = JSON.parse(mapData.shape);
            if (typeof(shape) !== 'object' || Object.keys(shape).length === 0) {
                shape = undefined;
            }
        } catch {}

        var id = `map_${mapData.map_id}_-1`;
        var map = {
            ...mapData,
            img_src: mapData.img_link ? `${mapData.img_link}&tiz=${new Date().getTime()}` : '',     // Under the smae map_id, img_link always the same. So add timestamp to load new image.
            shape: shape,
            node_type: EnumMapNodeType.ImgMap,
            key: id,
            id: id,
            main_name: mapData.map_name,
            main_id: mapData.map_id,
            sub_name: '',
            sub_id: -1
        };

        return map;
    };

    getMapList = (force, callback) => {
        callback = callback || function() {};

        if (force || !this._initData.mapList) {
            this.ajaxThirdParty(`${Constants.gvCloudAPIUrl}asm/maps`, 'GET', {}, (resp) => {
                if (resp.success && Array.isArray(resp.map_list)) {
                    var mapList = [];
                    resp.map_list.forEach(item => {
                        mapList.push(this._genMapFields(item));
                    });

                    this.setState({mapList: mapList}, () => {
                        this._initData.mapList = true;
                        callback({
                            errcode: EnumErrorCode.ERR_SUCCESS,
                            data: mapList,
                            errmsg: ''
                        });
                    });
                } else {
                    callback({
                        errcode: EnumErrorCode.ERR_INTERNAL_ERROR
                    });
                }
            });
        } else {
            callback({
				errcode: EnumErrorCode.ERR_SUCCESS,
				data: this.state.mapList,
				errmsg: ''
			});
			return this.state.mapList;
        }
    };

    getMapData = (map_id, callback) => {
        callback = callback || function() {};

        this.ajaxThirdParty(`${Constants.gvCloudAPIUrl}asm/map`, 'POST', {map_id: map_id}, (resp) => {
            if (resp.success && typeof(resp.map) === 'object') {
                var mapList = [...this.state.mapList];
                var mapData = this._genMapFields(resp.map);

                var index = mapList.findIndex(item => item.map_id === mapData.map_id);
                if (index === -1) {
                    mapList.push(mapData);
                } else {
                    mapList[index] = mapData;
                }

                this.setState({mapList: mapList}, () => {
                    callback(true, mapData);
                });
            } else {
                callback(false);
            }
        });
    };

    setMapPin = (map_id, sub_id, pin_data, callback) => {
        callback = callback || function() {};

        var reqParams = {
            cmd: 'update',
            map_id: map_id,
            ...pin_data
        };
        reqParams.audit_memo = JSON.stringify(this.genMapAuditMemo(reqParams));

        this.ajaxThirdParty(`${Constants.gvCloudAPIUrl}asm/map/edit`, 'POST', reqParams, (resp) => {
            if (resp.success && resp.map_id) {
                var mapList = [...this.state.mapList];
                var index = mapList.findIndex(item => item.map_id === map_id);
                if (index > -1) {
                    mapList[index] = {
                        ...mapList[index],
                        ...pin_data
                    };
                    this.setState({mapList: mapList}, () => {
                        callback(true);
                    });
                } else {
                    callback(false);
                }
            } else {
                callback(false);
            }
        });
    };

    setMapShape = (map_id, shape, callback) => {
        callback = callback || function() {};

        var reqParams = {
            cmd: 'update',
            map_id: map_id,
            shape: JSON.stringify(shape),
            audit_memo: JSON.stringify(this.genMapAuditMemo({map_id, shape}))
        };

        this.ajaxThirdParty(`${Constants.gvCloudAPIUrl}asm/map/edit`, 'POST', reqParams, (resp) => {
            if (resp.success && resp.map_id) {
                var mapList = [...this.state.mapList];
                var index = mapList.findIndex(item => item.map_id === map_id);
                if (index > -1) {
                    mapList[index] = {
                        ...mapList[index],
                        shape
                    };
                    this.setState({mapList: mapList}, () => {
                        callback(true);
                    });
                } else {
                    callback(false);
                }
            } else {
                callback(false);
            }
        });
    };

    deleteMap = (map_id, callback) => {
        callback = callback || function() {};

        var params = {
            cmd: 'delete',
            map_id,
            audit_memo: JSON.stringify(this.genMapAuditMemo({map_id}))
        };

        this.ajaxThirdParty(`${Constants.gvCloudAPIUrl}asm/map/edit`, 'POST', params, (resp) => {
            if (resp.success) {
                var mapList = [...this.state.mapList];
                var index = mapList.findIndex(item => item.map_id === map_id);
                if (index > -1) {
                    mapList.splice(index, 1);
                }

                this.setState({mapList: mapList}, () => {
                    callback(true);
                });
            } else {
                callback(false);
            }
        });
    };

    getVmsRegionList = (force, callback, notManual) => {
        callback = callback || function() {};
        if (force || !this._initData.vmsRegionList) {
            this.vmsGraphql.current.queryRegionList((success, resp) => {
                if (success) {
                    this.setState({
                        vmsRegionList: resp.data
                    }, () => {
                        this._initData.vmsRegionList = true;
                        callback({
                            errcode: EnumErrorCode.ERR_SUCCESS,
                            data: resp.data,
                            errmsg: ''
                        });
                    });
                } else {
                    callback({
                        errcode: EnumErrorCode.ERR_INTERNAL_ERROR,
                        errmsg: resp
                    });
                }
            });
        } else {
            callback({
				errcode: EnumErrorCode.ERR_SUCCESS,
				data: this.state.vmsRegionList,
				errmsg: ''
			});
			return this.state.vmsRegionList;
        }
    };

    getRegionList = (force, callback, notManual) => {
        callback = callback || function() {};
		if (force || !this._initData.regionList) {
            this.ajaxASCloud(Constants.urls.access, {api_cmd: EnumASCloudAPIType.REGION, action_type:EnumActionType.Query}, (resp) => {
				if (!resp.errcode) {
                    this.setState({
                        regionList: resp.data
                    }, () => {
                        this._initData.regionList = true;
                        callback({
                            errcode: EnumErrorCode.ERR_SUCCESS,
                            data: resp.data,
                            errmsg: ''
                        });
                    });
				} else {
					callback(resp);
				}
			}, notManual);
		} else {
			callback({
				errcode: EnumErrorCode.ERR_SUCCESS,
				data: this.state.regionList,
				errmsg: ''
			});
			return this.state.regionList;
		}
    };

    _genCameraFields = (host, camera) => {
        var pin_data = {};
        if (typeof(camera.pin_data) === 'string') {
            try {
                pin_data = JSON.parse(camera.pin_data);
            } catch {}
        } else if (typeof(camera.pin_data) === 'object') {
            pin_data = camera.pin_data;
        }
        delete camera.pid;
        delete camera.pos_x;
        delete camera.pos_y;
        
        var id = `cam-${host.id}-${camera.index}`;
        var cam = {
            ...camera,
            ...pin_data,
            node_type: EnumMapNodeType.Camera,
            rg_id: host.rg_id,
            id: id,
            key: id,
            main_name: host.display_name,
            sub_name: camera.name,
            main_id: host.id,
            sub_id: camera.index,
            live_cam: {
                host_code: host.host_code,
                relay_id: host.relay_host_id,
                camera_id: camera.index,
                streaming_id: host.streaming_id,
                streaming_pwd: host.streaming_pwd
            }
        };
        return cam;
    };

    getVMSList = (force, callback, notManual) => {
        callback = callback || function() {};

        if (force || !this._initData.vmsList) {
            clearTimeout(this._timerRefreshVMSList);
            this.ajaxThirdParty(`${Constants.gvCloudAPIUrl}asm/hosts/more_info`, 'GET', {}, (resp) => {
                if (!resp.errcode && Array.isArray(resp.host_list)) {
                    var cameraList = [];
                    resp.host_list.forEach(host => {
                        host.rg_id = host.region?.id;
                        var cameras = [];
                        if (Array.isArray(host.cameras)) {
                            host.cameras.forEach(camera => {
                                var camData = this._genCameraFields(host, camera);
                                cameras.push(camData);
                                cameraList.push(camData);
                            });
                        }
                        host.cameras = cameras;
                    });

                    this.setState({
                        vmsList: resp.host_list,
                        cameraList: cameraList
                    }, () => {
                        this._initData.vmsList = true;
                        callback({
                            errcode: EnumErrorCode.ERR_SUCCESS,
                            data: resp.host_list,
                            errmsg: ''
                        });
                    });
                } else {
                    callback(resp);
                }
                this._timerRefreshVMSList = setTimeout(() => {
                    this.getVMSList(true);
                }, 30000);
            });
        } else {
            callback({
				errcode: EnumErrorCode.ERR_SUCCESS,
				data: this.state.vmsList,
				errmsg: ''
			});
			return this.state.vmsList;
        }
    };

    setVMSPin = (host_id, cam_index, pin_data, callback) => {
        callback = callback || function() {};
        pin_data = typeof(pin_data) === 'object' ? pin_data : {};

        var callError = () => {
            callback({errcode: EnumErrorCode.ERR_INTERNAL_ERROR, success: 0});
        };

        this.ajaxThirdParty(`${Constants.gvCloudAPIUrl}asm/camera/pin_data`, 'POST', {
            host_id: host_id,
            cam_index: cam_index,
            pin_data: JSON.stringify(pin_data),
            audit_memo: JSON.stringify(this.genPinAuditMemo(`cam-${host_id}-${cam_index}`, pin_data))
        }, (resp) => {
            if (!resp.errcode) {
                var vmsList = [...this.state.vmsList],
                    cameraList = [...this.state.cameraList];
                
                var host = vmsList.find(item => item.id === host_id);
                if (!host) { callError(); return;}

                var camera = host.cameras.find(item => item.index === cam_index);
                if (!camera === -1) { callError(); return;}

                camera.pin_data = JSON.stringify(pin_data);

                var camData = this._genCameraFields(host, camera);
                Object.assign(camera, camData);

                var index = cameraList.findIndex(item => item.id === camData.id);
                if (index === -1) {
                    cameraList.push(camData);
                } else {
                    cameraList[index] = camData;
                }

                this.setState({
                    vmsList: vmsList,
                    cameraList: cameraList
                }, () => {
                    callback(resp);
                });
            } else {
                callback(resp);
            }
        });
    };

    _genGateFields = (device, gate) => {
        var live_cam = gate.live_cam;
        if (typeof(gate.live_cam) === 'string') {
            try {
                live_cam = JSON.parse(gate.live_cam);
            } catch {}
        }

        var pin_data = {};
        if (typeof(gate.pin_data) === 'string') {
            try {
                pin_data = JSON.parse(gate.pin_data);
            } catch {}
        } else if (typeof(gate.pin_data) === 'object') {
            pin_data = gate.pin_data;
        }
        delete gate.pid;
        delete gate.pos_x;
        delete gate.pos_y;

        if (typeof(gate.exitDoor) === 'object' && typeof(gate.exitDoor.live_cam) === 'string') {
            try {
                gate.exitDoor.live_cam = JSON.parse(gate.exitDoor.live_cam);
            } catch {}
        }

        var id = `door-${device.device_id}-${gate.gate_id}`;
        var _gate = {
            ...gate,
            ...pin_data,
            live_cam: live_cam,
            node_type: EnumMapNodeType.Door,
            id: id,
            key: id,
            main_name: device.device_name,
            sub_name: gate.gate_name,
            main_id: device.device_id,
            sub_id: gate.gate_id,
            device_mac: device.device_mac,
            device_status: device.device_status,
            version_status: device.version_status,
            rg_id: device.rg_id
        };
        return _gate;
    };

    getDeviceList = (force, callback, notManual) => {
		callback = callback || function() {};
		if (force || !this._initData.deviceList) {
            this._requestDeviceStatus = 1;
			this.ajaxASCloud(Constants.urls.access, {api_cmd: EnumASCloudAPIType.DEVICE, action_type: EnumActionType.Query}, (resp) => {
				if (!resp.errcode && Array.isArray(resp.data)) {
                    if (this._requestDeviceStatus === 2) {
                        this.getDeviceList(true, callback, notManual);
                    } else {
                        var gateList = [];                    
                        resp.data.forEach(device => {
                            var gates = [];

                            // device.gates.forEach((gate, index) => {
                            //     if (device.device_mac === '0013E2FFCB03' && gate.gate_id === 1) {
                            //         gate.gate_status = 1 + (1 << 8);
                            //     } else if (device.device_mac === '0013E2FFCB03' && gate.gate_id === 0) {
                            //         gate.gate_status = 1 + (1 << 5) + (1 << 4) + (1 << 11) + (1 << 6);
                            //     }
                            // });
                            device.gates.forEach((gate, index) => {
                                if (!gate.enable) return;
                                if (index > 0 && gate.gate_id % 2 === 1 && device.gates[index - 1].gate_id === (gate.gate_id - 1) && device.gates[index - 1].enable) {
                                    return;   // ignore exit door
                                }

                                // set exit door
                                if (gate.gate_id % 2 === 0 && index < device.gates.length - 1 && device.gates[index + 1].enable && device.gates[index + 1].gate_id === (gate.gate_id + 1)) {
                                    gate.exitDoor = device.gates[index + 1];
                                }

                                var gateData = this._genGateFields(device, gate);
                                gates.push(gateData);
                                gateList.push(gateData);
                            });
                            device.gates = gates;
                        });

                        this.setState({
                            deviceList: resp.data,
                            gateList: gateList
                        }, () => {
                            this._requestDeviceStatus = 0;
                            this._initData.deviceList = true;
                            callback({
                                errcode: EnumErrorCode.ERR_SUCCESS,
                                data: resp.data,
                                errmsg: ''
                            });
                        });
                    }
				} else {
                    this._requestDeviceStatus = 0;
					callback(resp);
				}
			}, notManual);
		} else {
			callback({
				errcode: EnumErrorCode.ERR_SUCCESS,
				data: this.state.deviceList,
				errmsg: ''
			});
			return this.state.deviceList;
		}
	};

    setDevicePin = (device_id, gate_id, pin_data, callback) => {
        callback = callback || function() {};
        pin_data = typeof(pin_data) === 'object' ? pin_data : {};

        var callError = () => {
            callback({errcode: EnumErrorCode.ERR_INTERNAL_ERROR, success: 0});
        };

        var params = {
            api_cmd: EnumASCloudAPIType.UPDATE_DEVICES_GATES_PIN,
            devices: [{
                device_id: device_id,
                gates: [{
                    gate_id: gate_id,
                    pin_data: JSON.stringify(pin_data)
                }]
            }],
            audit_memo: JSON.stringify(this.genPinAuditMemo(`door-${device_id}-${gate_id}`, pin_data))
        };

        this.ajaxASCloud(Constants.urls.access, params, (resp) => {
            if (!resp.errcode) {
                var deviceList = [...this.state.deviceList],
                    gateList = [...this.state.gateList];
                
                var device = deviceList.find(item => item.device_id === device_id);
                if (!device) { callError(); return;}

                var gate = device.gates.find(item => item.gate_id === gate_id);
                if (!gate) { callError(); return;}

                gate.pin_data = JSON.stringify(pin_data);

                var gateData = this._genGateFields(device, gate);
                Object.assign(gate, gateData);
                var index = gateList.findIndex(item => item.id === gateData.id);
                if (index === -1) {
                    gateList.push(gateData);
                } else {
                    gateList[index] = gateData;
                }

                this.setState({
                    deviceList: deviceList,
                    gateList: gateList
                }, () => {
                    callback(resp);
                });
            } else {
                callback(resp);
            }
        });
    };

    collectPins = (map_ids) => {
        var mapIds = [].concat(map_ids),
            _pins = [];

        [...this.state.mapList, ...this.state.cameraList, ...this.state.gateList].filter(item => mapIds.includes(item.pid)).forEach(pin => {
            _pins.push({
                ...pin,
                label: pin.node_type === EnumMapNodeType.ImgMap ? pin.main_name : this.getGateName(pin.main_name, pin.sub_name)
            });
        });
        return _pins;
    };

    getGateName = (device_name, gate_name, eFormat, gate_dir) => {
        eFormat = (eFormat === null || typeof(eFormat) === 'undefined') ? this.state.mapSettings.format : eFormat;
        var name;
        switch (eFormat) {
            case EnumGateFormat.DeviceGate:
                name = `${device_name} - ${gate_name}`; break;
            case EnumGateFormat.GateDevice:
                name = `${gate_name} - ${device_name}`; break;
            case EnumGateFormat.Gate:
                name = `${gate_name}`; break;
            default:
                break;
        }
        if (typeof(gate_dir) !== 'undefined' && gate_dir !== -1) {
            if (gate_dir === EnumDirectionType.Entry) {
                name += ` (${this.state.locale?.['entry']})`;
            } else if (gate_dir === EnumDirectionType.Exit) {
                name += ` (${this.state.locale?.['exit']})`;
            }
        }
        return name;
    };

    getLiveStreamInfo = (host_code, camera_id) => {
        const { live_password, exp, user_id } = jwt_decode(this.state.accountInfo.gv_user_token);
        return {
            username: `#!c2-${host_code}:${user_id.toString(16)}:${exp.toString(16)}:${camera_id}`, // #!c2-: from GV-Cloud
            password: live_password
        };
    };

    getAccessLogList = (param, callback) => {
        callback = callback || function() {};
        param = {
            ...param,
            log_type: EnumLogType.Access
        };
        this.ajaxASCloud(Constants.urls.querylog, param, (resp) => {
            if (!resp.errcode && Array.isArray(resp.data)) {
                resp.data.forEach(data => {
                    data['server_type'] = EnumServerType.Access;
                    data['log_type'] = EnumLogType.Access;
                    data['msg_type'] = IsEventLog(data.msg_id) ? EnumMessageType.Event : EnumMessageType.Access;
                    setLogSnapshotsSatuses(data);
                });
            }
            callback(resp);
        });
    };

    // getLPRLogList = (param, callback) => {
    //     callback = callback || function() {};
    //     param = {
    //         ...param,
    //         log_type: EnumLogType.LPR
    //     };
    //     this.ajaxASCloud(Constants.urls.querylog, param, (resp) => {
    //         if (!resp.errcode && Array.isArray(resp.data)) {
    //             resp.data.forEach(data=> {
    //                 data['server_type'] = EnumServerType.Access;
    //                 data['log_type'] = EnumLogType.LPR;
    //                 data['msg_type'] = EnumMessageType.LPR;
    //             });
    //         }
    //         callback(resp);
    //     });
    // };

    collectLogs = (data) => {
        var deviceId = -1, deviceMac = '', accessLogs = [], lprLogs = [], sysLogs = [];
        try {
            var {device_id, device_mac, access_datas, lpr_datas, sys_datas} = JSON.parse(data);
            deviceId = typeof(deviceId) === 'undefined' ? -1 : device_id;
            deviceMac = typeof(device_mac) === 'undefined' ? '' : device_mac;
            accessLogs = Array.isArray(access_datas) ? access_datas : [];
            lprLogs = Array.isArray(lpr_datas) ? lpr_datas : [];
            sysLogs = Array.isArray(sys_datas) ? sys_datas : [];
        } catch { return; }

        var device = this.state.deviceList.find(item => item.device_id === deviceId || item.device_mac === deviceMac);
        var logs = [];
        accessLogs.concat(lprLogs).concat(sysLogs).forEach(log => {
            var msg = EnumLogMessage.find(msg => msg.value === log.msg_id);
            if (!msg) return;

            var gate;
            if (device) {
                gate = device.gates.find(gate => gate.gate_id === log.gate_id && gate.enable);
            }

            logs.push({
                ...log,
                server_type: EnumServerType.Access,
                log_type: //msg.msgType === EnumMessageType.LPR ? EnumLogType.LPR : 
                          msg.msgType === EnumMessageType.System ? EnumLogType.System :
                          EnumLogType.Access,
                msg_type: msg.msgType,
                msg_format: msg.intlFormat,
                msg_warning: msg.warning,
                rg_id: device?.rg_id,
                device_id: deviceId,
                device_mac: deviceMac,
                device_name: device?.device_name,
                gate_name: gate?.gate_name,
                gate_dir: msg.directional ? (log.gate_id % 2) : -1
            });
        });

        return logs;
    };

    handleNotify = (notifyData) => {
        if (notifyData.cmd_id === EnumWebSocketCmd.LOG_UPLOADED) {
            notifyData.data2 = this.collectLogs(notifyData.data2);
        }

        this._receiveNotification(notifyData);
        this._callWsNotify(notifyData);
    };

    handleVMSNotify = (data) => {
        if (data?.type === 'PONG') return;
        var notifyData = {
            cmd_id: EnumWebSocketCmd.VMS_WEBSOCKET,
            ...data
        };
        this._receiveNotification(notifyData);
        this._callWsNotify(notifyData);
    };

    genMapAuditMemo = (mapData) => {
        const includeKeys = ['map_name', 'parent_name', 'pos_x', 'pos_y', 'shape'];
        var newMapData = {
            ...this.state.mapList.find(item => item.map_id === mapData?.map_id),
            ...mapData
        };

        if (newMapData.pid) {
            var parent = this.state.mapList.find(item => item.map_id === newMapData.pid);
            newMapData.parent_name = parent?.map_name || '';
        } else if (newMapData.pid === 0) {
            newMapData.parent_name = this.state.locale['google_map'];
        }

        var auditMemo = {};
        Object.entries(newMapData).forEach(([key, val]) => {
            if (includeKeys.includes(key)) {
                auditMemo[key] = val;
            }
        });

        return auditMemo;
    };

    genPinAuditMemo = (pin_id, pin_data) => {
        var auditMemo = {};
        var pin = [...this.state.gateList, ...this.state.cameraList].find(item => item.id === pin_id);
        if (pin) {
            var {pid, ...newPinData} = pin_data;
            if (pid) {
                var parent = this.state.mapList.find(item => item.map_id === pid);
                newPinData.parent_name = parent?.map_name || '';
            } else if (pid === 0) {
                newPinData.parent_name = this.state.locale['google_map'];
            }
            auditMemo = {
                pin_name: this.getGateName(pin.main_name, pin.sub_name, null, -1),
                pin_data: newPinData
            };
        }
        return auditMemo;
    };

    render() {
        return (
            <IntlProvider messages={this.state.locale} locale={this.state.accountInfo.lang}>
                <AppContext.Provider value={this.state}>
                    <VMSGraphql ref={this.vmsGraphql} onNotify={this.handleVMSNotify}>
                        <ASUtils ref={this.asUtils} serviceType={this.state.serviceType} token={this.state.accountInfo.gv_user_token} onNotify={this.handleNotify} />
                        <ASConfigComponent ref={this.asConfigRef} />
                        {
                            isWinPlatform &&
                            <WsPlayerDetector onSupport={(support, version) => {if (support){this.setState({wsPlayerConfig: {...this.state.wsPlayerConfig, isSupport: support, current_version: version }})}}} />
                        }
                        {this.props.children}
                    </VMSGraphql>
                </AppContext.Provider>
            </IntlProvider>
        );
    }
}

export function CopyObject(src) {
    return JSON.parse(JSON.stringify(src));
}

export function ArrayToggle(array, value) {
    if (Array.isArray(array)) {
        var index = array.indexOf(value);
        if (index === -1) {
            array.push(value);
        } else {
            array.splice(index, 1);
        }
    }
    return array;
}

export function FormatKey(...args) {
    return args.join('_');
}

export function getMapAppContainer() {
    return document.querySelector('.map-app') || document.body;
}