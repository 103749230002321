import React, { useState, useEffect, forwardRef, useMemo } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { Spin, Empty, ColorPicker } from 'antd';

import { EnumMajorFactor } from '../ASUtils/ASConfig';
import { Constants } from '../../Utils';
import SVGIcon from '../../icons.js';
import './Common.css';

export function LoadingMask(props) {
    return (
        <div className={`common-mask ${props.show? '' : ' hide'} ${props.transparentBackground? ' transparent' : ''}`}>
            <Spin size={props.size} tip={props.text} />
        </div>
    );
}
LoadingMask.defaultProps = {
    show: false,
    size: 'large',
    text: <FormattedMessage id="loading..." defaultMessage="Loading ..." />,
    transparentBackground: false
};

export function NoDataMask(props) {
    return (
        <Empty className='common-no-data' description={<FormattedMessage id='no_data' />} image={<SVGIcon.NoData />}>
        { props.children }
        </Empty>
    );
}

const ASImageCacheMap = {}; // by log {server_type}-{log_type}-{log_id}-{m_factor}-{index}, by user-{user_id}
const ASImageStatus = {
    Ignore: -1,
    CacheImage: 0,  // Use cache image
    ParmasImage: 1, // Use parameter image
    LoadError: 2,   // Image Load Error
    ForceSrc: 3     // force Use Src Image
};
export function ASImage({server_type, log_type, log_id, u_user_id, m_factor, index, src, alt, force, error_image, noErrorImage, onLoad, onError, ...props}) {
    const [imgSrc, setImgSrc] = useState(null);
    const [status, setStatus] = useState(ASImageStatus.CacheImage);

    const imgKey = useMemo(() => {
        var key;
        if (log_type === 'user') {
            if (u_user_id > -1) {
                if (u_user_id === 0) {
                    key = `${log_type}_all`;    // all user
                } else {
                    key = `${log_type}_${u_user_id}`;
                }
            }
        } else if (log_id > -1) {
            key = `${server_type}_${log_type}_${log_id}_${m_factor}_${index}`;
        }
        return key;
    }, [server_type, log_type, log_id, u_user_id, m_factor, index]);

    useEffect(() => {
        var cacheImg;
        if (force && src) {
            setStatus(ASImageStatus.ForceSrc);
            setImgSrc(src);
            return;
        } else if (imgKey) {
            if (imgKey === 'user_all') {
                cacheImg = null;
                setStatus(ASImageStatus.LoadError);
            } else {
                cacheImg = ASImageCacheMap[imgKey];
            }
        } else {
            if (src) {
                setStatus(ASImageStatus.Ignore);
                setImgSrc(src);
            } else {
                setStatus(ASImageStatus.LoadError);
                setImgSrc(error_image || null);
            }
            return;
        }

        if (cacheImg) {
            setImgSrc(cacheImg);
        } else if (src) {
            setStatus(ASImageStatus.ParmasImage);
            setImgSrc(src);
        } else {
            setStatus(ASImageStatus.LoadError);
            setImgSrc(error_image || null);
        }
    }, [imgKey, src, force, error_image]);

    const handleLoad = e => {
        if (imgKey && (status === ASImageStatus.ParmasImage || status === ASImageStatus.ForceSrc)) {
            ASImageCacheMap[imgKey] = e.target.src;
        }
        if (status === ASImageStatus.LoadError) {
            onError(e);
        } else {
            onLoad(e);
        }
    };

    const handleError = e => {
        if (status === ASImageStatus.CacheImage) {
            if (imgKey) {
                delete ASImageCacheMap[imgKey];
            }

            if (src) {
                setStatus(ASImageStatus.ParmasImage);
                setImgSrc(src);
            } else {
                setStatus(ASImageStatus.Ignore);
                setImgSrc(error_image || null);
            }
        } else if (status === ASImageStatus.ForceSrc) {
            var cacheImg = imgKey ? ASImageCacheMap[imgKey] : null;
            if (cacheImg) {
                setStatus(ASImageStatus.CacheImage);
                setImgSrc(cacheImg);
            } else {
                if (noErrorImage) {
                    onError(e);
                } else {
                    setStatus(ASImageStatus.LoadError);
                    if (imgKey) {
                        setImgSrc(error_image || null);
                    } else {
                        onError(e);
                    }
                }
            }
        } else {
            setStatus(ASImageStatus.LoadError);
            if (noErrorImage) {
                onError(e);
            } else {
                if (imgKey) {
                    setImgSrc(error_image || null);
                } else {
                    onError(e);
                }
            }
        }
    };

    return (
        <img {...props} alt={alt} src={imgSrc} onLoad={handleLoad} onError={handleError} />
    );
}
ASImage.defaultProps = {
    server_type: -1,
    log_type: -1,
    log_id: -1,
    u_user_id: -1,
    m_factor: EnumMajorFactor.Gate,
    index: 0,
    src: '',
    alt: '',
    force: false,
    onLoad: function() {},
    onError: function(e) {},
    error_image: null,
    noErrorImage: false
};


const getRecentColors = () => {
    var recentColor = [];
    try {
        recentColor = JSON.parse(window.localStorage.getItem(Constants.storageNames.recentColor));
        if (!Array.isArray(recentColor)) {
            recentColor = [];
        }
    } catch {}
    return recentColor;
};
export const setRecentColors = (colors) => {
    colors = [].concat(colors);
    var recentColor = getRecentColors();
    colors.forEach(color => {
        var index = recentColor.indexOf(color.toLowerCase());
        if (index > -1) {
            recentColor.splice(index, 1);
        }
        recentColor.splice(0, 0, color.toLowerCase());
    });
    while (recentColor.length > 10) {
        recentColor.pop();
    }
    window.localStorage.setItem(Constants.storageNames.recentColor, JSON.stringify(recentColor));
};
export const ASColorPicker = forwardRef((props, ref) => {
    const recommended = ['#00000080','#7F7F7F80','#88001580','#ED1C2480','#FF7F2780','#FFF20080','#22B14C80','#00A2E880','#3F48CC80','#A349A480',
                         '#FFFFFF80','#C3C3C380','#B97A5780','#FFAEC980','#FFC90E80','#EFE4B080','#B5E61D80','#99D9EA80','#7092BE80','#C8BFE780'];
    const intl = useIntl();
    const [recent, setRecent] = useState([]);
    const [format, setFormat] = useState('rgb');
    useEffect(() => {
        setRecent(getRecentColors());
    }, []);
    const handleChange = (value, hex) => {
        props.onChange(value.toHexString());
    };

    return (
        <ColorPicker {...props} presets={[{label: intl.formatMessage({id: 'recommended_color'}), colors: recommended}, {label: intl.formatMessage({id: 'recent_color'}), colors: recent}]}
            format={format} onFormatChange={value => setFormat(value)}
            onChange={handleChange}
        />
    );
});

export const getTreeSwitcherIcon = (nodeProps) => {
    return <SVGIcon.ArrowDropDown className={nodeProps.expanded ? '' : 'collapsed'} />;
};
export const getCollapseExpandIcon = (panelProps) => {
    return <SVGIcon.ArrowLineDown className={panelProps.isActive ? 'expanded' : ''} />;
};