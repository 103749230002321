
import { forwardRef } from 'react';

import { ReactComponent as LogoAccessControl } from './images/site/logo_access_control.svg';
import { ReactComponent as LogoVideoMonitor } from './images/site/logo_video_monitor.svg';
import { ReactComponent as LogoMap } from './images/site/logo_map.svg';
import { ReactComponent as LogoIOT } from './images/site/logo_iot.svg';
import { ReactComponent as LogoAccountSetting } from './images/site/logo_account_setting.svg';
import { ReactComponent as LogoAuditLog } from './images/site/logo_audit_log.svg';
import { ReactComponent as LogoVPN } from './images/site/logo_vpn.svg';

import { ReactComponent as Edit } from './images/edit.svg';
import { ReactComponent as Setting } from './images/setting.svg';
import { ReactComponent as Logout } from './images/logout.svg';
import { ReactComponent as FullScreen } from './images/full_screen.svg';
import { ReactComponent as Trash } from './images/trash.svg';
import { ReactComponent as Position } from './images/position.svg';
import { ReactComponent as AutoRoateRaw } from './images/auto_rotate.svg';
import { ReactComponent as ArrowDropDownRaw } from './images/arrow_drop_down.svg';
import { ReactComponent as ArrowLineDownRaw } from './images/arrow_line_down.svg';
import { ReactComponent as SelectArrowDownRaw } from './images/arrow_line_down.svg';
import { ReactComponent as DeleteCircleRaw } from './images/delete_red.svg';

import { ReactComponent as QueryPhotoMode } from './images/querylog_photo.svg';
import { ReactComponent as QueryListMode } from './images/querylog_list.svg';
import { ReactComponent as LiveVideo } from './images/live_video.svg';
import { ReactComponent as Playback } from './images/playback.svg';
import { ReactComponent as Snapshot } from './images/snapshot.svg';
import { ReactComponent as VideoPause } from './images/video_pause.svg';
import { ReactComponent as VideoPlay } from './images/video_play.svg';
import { ReactComponent as ViewBox21 } from './images/btn_viewbox2_1.svg';
import { ReactComponent as ViewBox22 } from './images/btn_viewbox2_2.svg';
import { ReactComponent as ViewBox32 } from './images/btn_viewbox3_2.svg';
import { ReactComponent as ViewBox31 } from './images/btn_viewbox3_1.svg';
import { ReactComponent as WSplayerDownload } from './images/wsplayer_download.svg';
import { ReactComponent as WSplayer } from './images/wsplayer.svg';
import { ReactComponent as JSplayer } from './images/jsplayer.svg';

import { ReactComponent as Filter } from './images/filter.svg';
import { ReactComponent as AutoSelect } from './images/auto_select.svg';
import { ReactComponent as NoDataRaw } from './images/no_data.svg';
import { ReactComponent as Warning } from './images/ic_warning.svg';

import { ReactComponent as FactorCard } from './images/factor_card.svg';
import { ReactComponent as FactorVehicle } from './images/factor_vehicle.svg';
import { ReactComponent as FactorFace } from './images/factor_face.svg';
import { ReactComponent as FactorFinger } from './images/factor_finger.svg';

import { ReactComponent as MapRaw } from './images/map/map.svg';
import { ReactComponent as RegionRaw } from './images/region.svg';
import { ReactComponent as DeviceRaw } from './images/device.svg';

import { ReactComponent as Host } from './images/host.svg';
import { ReactComponent as Camera } from './images/camera.svg';
import { ReactComponent as CameraDisconnected } from './images/map/camera_disconnect_.svg';

import { ReactComponent as Gate } from './images/map/door.svg';
import { ReactComponent as GateGreen } from './images/map/door.svg';
import { ReactComponent as GateGreenOpen } from './images/map/door_open.svg';
import { ReactComponent as GateRed } from './images/map/door_r.svg';
import { ReactComponent as GateRedOpen } from './images/map/door_open_r.svg';
import { ReactComponent as GateDisconnected } from './images/map/door_disconnect.svg';
import { ReactComponent as GateForceLock } from './images/map/door_forced_lock.svg';
import { ReactComponent as GateForceUnlock } from './images/map/door_forced_unlock.svg';
import { ReactComponent as GateNoLicense } from './images/map/door_nolicense.svg';
import { ReactComponent as GateRelease } from './images/map/door_release.svg';
import { ReactComponent as Lock } from './images/map/door_lock.svg';
import { ReactComponent as Unlock } from './images/map/door_unlock.svg';
import { ReactComponent as Lockdown } from './images/map/door_lockdown.svg';
import { ReactComponent as LockdownFail } from './images/map/door_lockdown_error.svg';
// import { ReactComponent as Gate } from './images/gate.svg';
// import { ReactComponent as GateGreen } from './images/gate_green.svg';
// import { ReactComponent as GateGreenOpen } from './images/gate_green_open.svg';
// import { ReactComponent as GateRed } from './images/gate_red.svg';
// import { ReactComponent as GateRedOpen } from './images/gate_red_open.svg';
// import { ReactComponent as GateDisconnected } from './images/gate_disconnected.svg';
// import { ReactComponent as GateForceLock } from './images/gate_force_lock.svg';
// import { ReactComponent as GateForceUnlock } from './images/gate_force_unlock.svg';
// import { ReactComponent as GateNoLicense } from './images/gate_no_license.svg';
// import { ReactComponent as Lock } from './images/lock.svg';
// import { ReactComponent as Unlock } from './images/unlock.svg';
// import { ReactComponent as Lockdown } from './images/lockdown.svg';
// import { ReactComponent as LockdownFail } from './images/lockdown_fail.svg';

import { ReactComponent as DoorAlarm } from './images/door_alarm/alarm.svg';
import { ReactComponent as DoorDepredation } from './images/door_alarm/depredation.svg';
import { ReactComponent as DoorDuress } from './images/door_alarm/duress.svg';
import { ReactComponent as DoorFire } from './images/door_alarm/fire.svg';
import { ReactComponent as DoorForceOpen } from './images/door_alarm/forceopen.svg';
import { ReactComponent as DoorHeldOpen } from './images/door_alarm/heldopen.svg';
import { ReactComponent as DoorTailgating } from './images/door_alarm/tailgating.svg';
import { ReactComponent as DoorCamDisconnected } from './images/door_alarm/cam_disconnected.svg';

import { ReactComponent as MapDoor } from './images/map/door.svg';
import { ReactComponent as MapCamera } from './images/map/camera.svg';

import { ReactComponent as PlaybackLocal } from './images/rpb_local.svg';
import { ReactComponent as PlaybackCloud } from './images/rpb_cloud.svg';

const DeleteCircle = forwardRef((props, ref) => {
    return <DeleteCircleRaw {...props} ref={ref} className={`delete-circle ${props.className || ''}`} />
});

const Map = forwardRef((props, ref) => {
    return <MapRaw {...props} ref={ref} className={`map-icon ${props.className || ''}`} />
});
const Region = forwardRef((props, ref) => {
    return <RegionRaw {...props} ref={ref} className={`region-icon ${props.className || ''}`} />
});
const Device = forwardRef((props, ref) => {
    return <DeviceRaw {...props} ref={ref} className={`device-icon ${props.className || ''}`} />
});

const ArrowDropDown = forwardRef((props, ref) => {
    return <ArrowDropDownRaw {...props} ref={ref} className={`icon-arrow-drop-down ${props.className || ''}`} />
});
const ArrowLineDown = forwardRef((props, ref) => {
    return <ArrowLineDownRaw {...props} ref={ref} className={`icon-arrow-line-down ${props.className || ''}`} />
});
const SelectArrowDown = forwardRef((props, ref) => {
    return <SelectArrowDownRaw {...props} ref={ref} className={`icon-select-arrow-down ${props.className || ''}`} />
});

const NoData = forwardRef((props, ref) => {
    return <NoDataRaw {...props} ref={ref} className={`icon-no-data ${props.className || ''}`} />
});

const AutoRoate = forwardRef((props, ref) => {
    return <AutoRoateRaw {...props} ref={ref} className={`icon-auto-rotate ${props.className || ''}`} />
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    LogoAccessControl, LogoVideoMonitor, LogoMap, LogoIOT, LogoAccountSetting, LogoAuditLog, LogoVPN,
    Edit, Setting, Logout, FullScreen, Trash, Position, AutoRoate, ArrowDropDown, ArrowLineDown, SelectArrowDown, DeleteCircle,
    QueryPhotoMode, QueryListMode, LiveVideo, Playback, Snapshot, VideoPause, VideoPlay, ViewBox21, ViewBox22, ViewBox32, ViewBox31, 
    Filter, AutoSelect, NoData, Warning, WSplayerDownload, WSplayer, JSplayer,
    FactorCard, FactorVehicle, FactorFace, FactorFinger,
    Map, Region, Device, Host, Camera, CameraDisconnected,
    Gate, GateGreen, GateGreenOpen, GateRed, GateRedOpen, GateDisconnected, GateForceLock, GateForceUnlock, GateNoLicense, GateRelease, Lock, Unlock, Lockdown, LockdownFail,
    DoorAlarm, DoorDepredation, DoorDuress, DoorFire, DoorForceOpen, DoorHeldOpen, DoorTailgating, DoorCamDisconnected,
    MapDoor, MapCamera,
    PlaybackLocal, PlaybackCloud
};